import Pages from './Pages';

function App() {
  return (
    <div className="bg-teal-darker">
        <Pages />
    </div>
  );
}

export default App;
